import React, {useState} from 'react';
//import {Accordion} from 'react-bootstrap';
import windowSize from '../windowSize';

//const DEFAULT_STATE = {"0": true, "1": false, "2": false};
//const INIT_STATE = {"0": false, "1": false, "2": false};

function Recruit({windowWidth}) {
    //const [activeEventKey, setActiveEventKey] = useState(DEFAULT_STATE);
    //const onSelect = key => setActiveEventKey({...INIT_STATE, [key]: !activeEventKey[key]});
    return (
        <>
            <div className="SubBanner" style={getImageUrl(windowWidth)}>
                <div className="subBanner-title">RECRUIT</div>
            </div>
            {/* <div className="subContent recruit-top-margin">
              <Accordion defaultActiveKey="0">
                <GamePlanner20191209 eventKey="0" activeEventKey={activeEventKey} onSelect={onSelect} />
              </Accordion>
            </div> */}
            <div className="center">
                <div>채용 중인 공고가 없습니다.</div>
            </div>
        </>
    );
}

const getImageUrl = windowWidth => (
    windowWidth >= 576 ? 
    {backgroundImage: 'url(/image/img_sub_recruit.png)'}
    : {
        backgroundImage: 'url(/mobile/img_sub_recruit.png)',
        backgroundSize: `${windowWidth}px`,
        backgroundRepeat: 'no-repeat',
    });
  
export default windowSize(Recruit);