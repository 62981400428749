import React from "react"

import Layout from "../components/layout"
import Recruit from '../components/recruit/recruit';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/style.css';

const CompanyInformation = () => (
  <Layout>
    <Recruit />
  </Layout>
)

export default CompanyInformation
